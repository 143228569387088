import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMeasure } from "react-use";
import OptionsFoldout from "./OptionsFoldout";

import { Footer } from "./Footer";
import { Header } from "./Header";
import cm from "./assets/cm.svg";

import deleteIcon from "./assets/delete-icon.svg";
import editIcon from "./assets/edit.svg";
import TextOptions from "./TextOptions";
import ShapeOptions from "./ShapeOptions";
import FilterOptions from "./FilterOptions";
import PropTypes from "prop-types";

/**
 * Primary UI component for user interaction
 */
export const GraphicLab = ({ printAreaX = 10, printAreaY = 7, ...props }) => {
  const { editor, onReady } = useFabricJSEditor();
  const [ref, { width, height }] = useMeasure();

  const deleteIconElRef = useRef(null);
  const editIconElRef = useRef(null);
  const imageSelectRef = useRef(null);
  const shapeSelectRef = useRef(null);

  const [foldoutOpen, setFoldoutOpen] = useState(null);
  const [rerenderState, setRerenderState] = useState(1.1);

  const size = useMemo(() => {
    const aspectRatioArea = printAreaX / printAreaY;
    const canvasWrapperAspect = width / height;

    if (canvasWrapperAspect > aspectRatioArea) {
      return {
        x: (printAreaX * height) / printAreaY,
        y: height,
      };
    } else {
      return {
        x: width,
        y: (printAreaY * width) / printAreaX,
      };
    }
  }, [printAreaX, printAreaY, width, height]);

  const cmMarkers = useRef({ horizontal: [], vertical: [] });
  const [cmIndicatorSvg, setCmIndicatorSvg] = useState(null);

  useEffect(() => {
    if (editor) {
      editor.canvas.setDimensions({
        width: size.x,
        height: size.y,
      });
    }
  }, [size, editor]);

  useEffect(() => {
    if (fabric && editor) {
      editor.canvas.setBackgroundColor("#F9F9F9");
    }
  }, [editor]);

  const renderDeleteIcon = useCallback(
    (ctx, left, top, styleOverride, fabricObject) => {
      var size = 40;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(deleteIconElRef.current, -size / 2, -size / 2, size, size);
      ctx.restore();
    },
    []
  );

  const renderEditIcon = useCallback(
    (ctx, left, top, styleOverride, fabricObject) => {
      var size = 40;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(editIconElRef.current, -size / 2, -size / 2, size, size);
      ctx.restore();
    },
    []
  );

  const deleteObject = useCallback((event, transform) => {
    const { target } = transform;
    const { canvas } = target;
    canvas.remove(target);
    canvas.requestRenderAll();
  }, []);

  const editObject = useCallback((event, action) => {
    const { target } = action;
    const type = target.get("type");
    switch (type) {
      case "i-text":
        setFoldoutOpen({
          type: "editText",
          context: target,
        });
        break;
      case "circle":
      case "rect":
      case "triangle":
      case "path":
      case "group":
        setFoldoutOpen({
          type: "editShape",
          context: target,
        });
        break;
      case "image":
        setFoldoutOpen({
          type: "editImage",
          context: target,
        });
        break;
      default:
        break;
    }
  }, []);

  const onRefresh = useCallback(() => {
    // console.log(editor.canvas.getObjects());
    const objs = editor.canvas.getObjects();
    const objsToRemove = objs.slice(
      0,
      -(cmMarkers.current.horizontal.length + cmMarkers.current.vertical.length)
    );

    editor.canvas.remove(...objsToRemove);
    setRerenderState(Math.random());
  }, [editor]);

  useEffect(() => {
    fabric.loadSVGFromURL(cm, (object, options) => {
      setCmIndicatorSvg({
        object,
        options,
      });
    });

    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: -0.5,
      y: -0.5,
      offsetX: -15,
      offsetY: -15,
      cursorStyle: "pointer",
      mouseUpHandler: deleteObject,
      render: renderDeleteIcon,
      cornerSize: 24,
    });

    fabric.Object.prototype.controls.editControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetX: 15,
      offsetY: -15,
      cursorStyle: "pointer",
      mouseUpHandler: editObject,
      render: renderEditIcon,
      cornerSize: 24,
    });
  }, [deleteObject, editObject, renderDeleteIcon, renderEditIcon]);

  useEffect(() => {
    if (editor && cmIndicatorSvg) {
      const ceilX = Math.ceil(printAreaX);
      const ceilY = Math.ceil(printAreaY);
      const originalObj = fabric.util.groupSVGElements(
        cmIndicatorSvg.object,
        cmIndicatorSvg.options
      );

      // Horizontal ticks
      for (let i = cmMarkers.current.horizontal.length; i < ceilX; ++i) {
        originalObj.clone((clone) => {
          clone.set({
            hasBorders: false,
            hasControls: false,
            hasRotatingPoint: false,
            selectable: false,
            lockMovementX: true,
            lockMovementY: true,
            lockRotation: true,
            lockScalingFlip: true,
            lockScalingX: true,
            lockScalingY: true,
          });
          editor.canvas.add(clone);
          cmMarkers.current.horizontal.push(clone);
        });
      }
      for (let i = ceilX; i < cmMarkers.current.horizontal.length; ++i) {
        editor.canvas.remove(cmMarkers.current.horizontal[i]);
      }
      cmMarkers.current.horizontal = cmMarkers.current.horizontal.slice(
        0,
        ceilX
      );
      const w = size.x / ceilX;
      for (let i = 0; i < cmMarkers.current.horizontal.length; ++i) {
        const obj = cmMarkers.current.horizontal[i];
        obj.scaleToWidth(w);
        obj.left = i * w;
        obj.top = 0;
        obj.moveTo(10000);
      }

      // Vertical ticks
      for (let i = cmMarkers.current.vertical.length; i < ceilY; ++i) {
        originalObj.clone((clone) => {
          clone.set({
            hasBorders: false,
            hasControls: false,
            hasRotatingPoint: false,
            selectable: false,
            lockMovementX: true,
            lockMovementY: true,
            lockRotation: true,
            lockScalingFlip: true,
            lockScalingX: true,
            lockScalingY: true,
          });
          clone.rotate(-90);
          editor.canvas.add(clone);
          cmMarkers.current.vertical.push(clone);
        });
      }
      for (let i = ceilX; i < cmMarkers.current.vertical.length; ++i) {
        editor.canvas.remove(cmMarkers.current.vertical[i]);
      }
      cmMarkers.current.vertical = cmMarkers.current.vertical.slice(0, ceilY);
      const h = size.y / ceilY;

      for (let i = 0; i < cmMarkers.current.vertical.length; ++i) {
        const obj = cmMarkers.current.vertical[i];
        obj.scaleToHeight(h);
        obj.left = 0;
        obj.top = i * h + h;
        obj.moveTo(10000);
      }
      editor.canvas.requestRenderAll();
    }
  }, [editor, cmIndicatorSvg, printAreaX, printAreaY, size, rerenderState]);

  const onAddTextClicked = useCallback(() => {
    editor.canvas.insertAt(
      new fabric.IText("Tap and Type", {
        fontFamily: "arial black",
        left: 100,
        top: 100,
      }),
      0
    );
  }, [editor]);

  const onAddImageClicked = useCallback(() => {
    imageSelectRef.current.click();
  }, []);

  const onAddShapeClicked = useCallback(() => {
    setFoldoutOpen({
      type: "addShape",
    });
  }, []);

  const closeFoldout = useCallback(() => {
    setFoldoutOpen(null);
  }, []);

  const onFileSelected = useCallback(
    (ev) => {
      if (ev.target.files.length) {
        const file = ev.target.files[0];
        const reader = new FileReader();
        reader.onload = (f) => {
          var data = f.target.result;
          fabric.Image.fromURL(data, function (img) {
            editor.canvas.insertAt(img, 0).renderAll();
          });
        };
        reader.readAsDataURL(file);
      }
      ev.target.value = null;
    },
    [editor]
  );

  const onShapeFileSelected = useCallback(
    (ev) => {
      if (ev.target.files.length) {
        const file = ev.target.files[0];
        const reader = new FileReader();
        reader.onload = (f) => {
          var data = f.target.result;
          fabric.loadSVGFromString(data, function (img) {
            const obj = fabric.util.groupSVGElements(img);
            obj.set({
              left: 50,
              top: 5,
            });
            // obj.scaleToHeight(50);
            editor.canvas.insertAt(obj, 0).renderAll();
          });
        };
        reader.readAsText(file);
      }
      ev.target.value = null;
    },
    [editor]
  );

  const addShape = useCallback(
    (shape) => {
      // setFoldoutOpen(null);
      let shapeObj;
      switch (shape) {
        case "rect":
          shapeObj = new fabric.Rect({
            left: 50,
            top: 50,
            width: 50,
            height: 50,
          });
          break;
        case "circle":
          shapeObj = new fabric.Circle({
            left: 50,
            top: 50,
            radius: 50,
            fill: "#000000",
          });
          break;
        case "triangle":
          shapeObj = new fabric.Triangle({
            left: 50,
            top: 50,
            width: 50,
            height: 50,
            fill: "#000000",
          });
          break;
        case "vector":
          shapeSelectRef.current.click();
          break;
        default:
          break;
      }
      if (shapeObj) {
        editor.canvas.add(shapeObj);
        editor.canvas.setActiveObject(shapeObj);
        editor.canvas.requestRenderAll();
      }
    },
    [editor]
  );

  let foldoutContent;
  switch (foldoutOpen && foldoutOpen.type) {
    case "addShape":
      foldoutContent = (
        <div className="container m-8">
          Add simple shapes to canvas:
          <div className="flex flex-row gap-x-2 gap-y-3 flex-wrap mt-4">
            <button
              className="border border-white rounded-md bg-white px-5 py-1 m-0"
              onClick={() => addShape("rect")}
            >
              Rectangle
            </button>
            <button
              className="border border-white rounded-md bg-white px-5 py-1 m-0"
              onClick={() => addShape("circle")}
            >
              Circle
            </button>
            <button
              className="border border-white rounded-md bg-white px-5 py-1 m-0"
              onClick={() => addShape("triangle")}
            >
              Triangle
            </button>
            <button
              className="border border-white rounded-md bg-white px-5 py-1 m-0"
              onClick={() => addShape("vector")}
            >
              Custom Vector
            </button>
            {/* <button
              className="border border-white rounded-md bg-white px-5 py-1 m-0"
              onClick={() => addShape("line")}
            >
              Line
            </button>
            <button className="border border-white rounded-md bg-white px-5 py-1 m-0">
              Polygon
            </button> */}
          </div>
        </div>
      );
      break;
    case "editText":
      foldoutContent = <TextOptions textObject={foldoutOpen.context} />;
      break;
    case "editShape":
      foldoutContent = <ShapeOptions shapeObject={foldoutOpen.context} />;
      break;
    case "editImage":
      foldoutContent = <FilterOptions targetObject={foldoutOpen.context} />;
      break;
    default:
      break;
  }

  return (
    <div className="h-screen min-h-0 max-h-screen flex flex-col overflow-hidden">
      <Header onRefresh={onRefresh} />
      <div className="flex-1 min-h-0 w-full flex flex-col relative overflow-hidden">
        <div className="flex-1 min-h-0 container mx-auto mt-4 flex flex-col overflow-hidden">
          <div>
            Print area {printAreaX}cm x {printAreaY}cm
          </div>
          <div
            ref={ref}
            className="flex-1 flex items-center w-full p-4 overflow-hidden"
          >
            <div
              style={{
                width: size.x,
                height: size.y,
              }}
              className="mx-auto"
            >
              <FabricJSCanvas onReady={onReady} />
            </div>
          </div>
        </div>
        <OptionsFoldout isOpen={foldoutOpen} close={closeFoldout}>
          {foldoutContent}
        </OptionsFoldout>
      </div>
      <div className="hidden">
        <img alt="delete" src={deleteIcon} ref={deleteIconElRef} />
        <img alt="edit" src={editIcon} ref={editIconElRef} />
        <input type="file" ref={imageSelectRef} onChange={onFileSelected} />
        <input
          type="file"
          accept="image/svg+xml"
          ref={shapeSelectRef}
          onChange={onShapeFileSelected}
        />
        {/* <input type="file" ref={fileSelectRef} onChange={onFileSelected} /> */}
      </div>
      <Footer
        onAddTextClick={onAddTextClicked}
        onAddImageClick={onAddImageClicked}
        onAddShapeClick={onAddShapeClicked}
      />
    </div>
  );
};

GraphicLab.propTypes = {
  printAreaX: PropTypes.number,
  printAreaY: PropTypes.number,
};

GraphicLab.defaultProps = {
  printAreaX: 10,
  printAreaY: 7,
};
