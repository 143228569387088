import { useCallback, useState, useMemo, useEffect } from "react";
import { fabric } from "fabric";
import { ChromePicker } from "react-color";
// import dropdownIcon from "./assets/dropdown.svg";
import StandardRange from "./StandardRange";

const filterIndices = {
  BRIGHTNESS: 0,
  SATURATION: 1,
  REMOVE_COLOR: 2,
  GRAYSCALE: 3,
  BLACK_AND_WHITE: 4,
};

const f = fabric.Image.filters;

const FilterOptions = ({ targetObject }) => {
  const [rerenderState, setRerenderState] = useState(1.1);

  const [removeColorPickerState, setRemoveColorPickerState] = useState(false);

  const [brightnessVal, setBrightnessVal] = useState(0.5);
  const [saturationVal, setSaturationVal] = useState(0.5);

  const [removeColor, setRemoveColor] = useState("#000000");
  const [removeColorDistance, setRemoveColorDistance] = useState(0.1);

  const hasBrightnessFilter = useMemo(() => {
    if (rerenderState && targetObject) {
      return !!targetObject.filters[filterIndices.BRIGHTNESS];
    }
    return false;
  }, [targetObject, rerenderState]);

  const hasSaturationFilter = useMemo(() => {
    if (rerenderState && targetObject) {
      return !!targetObject.filters[filterIndices.SATURATION];
    }
    return false;
  }, [targetObject, rerenderState]);

  const hasRemoveColorFilter = useMemo(() => {
    if (rerenderState && targetObject) {
      return !!targetObject.filters[filterIndices.REMOVE_COLOR];
    }
    return false;
  }, [targetObject, rerenderState]);

  const hasBlackAndWhiteFilter = useMemo(() => {
    if (rerenderState && targetObject) {
      return !!targetObject.filters[filterIndices.BLACK_AND_WHITE];
    }
    return false;
  }, [targetObject, rerenderState]);

  const hasGrayscaleFilter = useMemo(() => {
    if (rerenderState && targetObject) {
      return !!targetObject.filters[filterIndices.GRAYSCALE];
    }
    return false;
  }, [targetObject, rerenderState]);

  useEffect(() => {
    if (targetObject.filters[filterIndices.BRIGHTNESS]) {
      setBrightnessVal(
        targetObject.filters[filterIndices.BRIGHTNESS].brightness
      );
    }
    if (targetObject.filters[filterIndices.SATURATION]) {
      setSaturationVal(
        targetObject.filters[filterIndices.SATURATION].saturation
      );
    }
    if (targetObject.filters[filterIndices.REMOVE_COLOR]) {
      setRemoveColor(targetObject.filters[filterIndices.REMOVE_COLOR].color);
      setRemoveColorDistance(
        targetObject.filters[filterIndices.REMOVE_COLOR].distance
      );
    }
  }, [targetObject]);

  const toggleRemoveColorPicker = useCallback(() => {
    setRemoveColorPickerState(!removeColorPickerState);
  }, [removeColorPickerState]);

  const toggleBrightness = useCallback(
    (e) => {
      if (e.target.checked) {
        targetObject.filters[filterIndices.BRIGHTNESS] = new f.Brightness({
          brightness: brightnessVal,
        });
        targetObject.applyFilters();
      } else {
        targetObject.filters[filterIndices.BRIGHTNESS] = false;
        targetObject.applyFilters();
      }
      setRerenderState(Math.random());
      targetObject.canvas.renderAll();
    },
    [targetObject, brightnessVal]
  );

  const brightnessChanged = useCallback(
    (vals) => {
      if (targetObject.filters[filterIndices.BRIGHTNESS]) {
        targetObject.filters[filterIndices.BRIGHTNESS].brightness = vals[0];
        targetObject.applyFilters();
      }
      targetObject.canvas.renderAll();
      setBrightnessVal(vals[0]);
      setRerenderState(Math.random());
    },
    [targetObject]
  );

  const toggleSaturation = useCallback(
    (e) => {
      if (e.target.checked) {
        targetObject.filters[filterIndices.SATURATION] = new f.Saturation({
          saturation: saturationVal,
        });
      } else {
        targetObject.filters[filterIndices.SATURATION] = false;
      }
      targetObject.applyFilters();
      targetObject.canvas.renderAll();
      setRerenderState(Math.random());
    },
    [targetObject, saturationVal]
  );

  const saturationChanged = useCallback(
    (vals) => {
      if (targetObject.filters[filterIndices.SATURATION]) {
        targetObject.filters[filterIndices.SATURATION].saturation = vals[0];
        targetObject.applyFilters();
      }
      targetObject.canvas.renderAll();
      setSaturationVal(vals[0]);
      setRerenderState(Math.random());
    },
    [targetObject]
  );

  const toggleRemoveColor = useCallback(
    (e) => {
      if (e.target.checked) {
        targetObject.filters[filterIndices.REMOVE_COLOR] = new f.RemoveColor({
          color: removeColor,
          distance: removeColorDistance,
        });
      } else {
        targetObject.filters[filterIndices.REMOVE_COLOR] = false;
      }
      targetObject.applyFilters();
      targetObject.canvas.renderAll();
      setRerenderState(Math.random());
    },
    [targetObject, removeColor, removeColorDistance]
  );

  const removeColorChanged = useCallback(
    (color) => {
      console.log(color);
      if (targetObject.filters[filterIndices.REMOVE_COLOR]) {
        targetObject.filters[filterIndices.REMOVE_COLOR].color = color.hex;
        targetObject.applyFilters();
      }
      targetObject.canvas.renderAll();
      setRemoveColor(color.hex);
      setRerenderState(Math.random());
    },
    [targetObject]
  );

  const removeDistanceChanged = useCallback(
    (vals) => {
      if (targetObject.filters[filterIndices.REMOVE_COLOR]) {
        targetObject.filters[filterIndices.REMOVE_COLOR].distance = vals[0];
        targetObject.applyFilters();
      }
      targetObject.canvas.renderAll();
      setRemoveColorDistance(vals[0]);
      setRerenderState(Math.random());
    },
    [targetObject]
  );

  const toggleBW = useCallback(
    (e) => {
      if (e.target.checked) {
        targetObject.filters[filterIndices.BLACK_AND_WHITE] = new f.BlackWhite();
      } else {
        targetObject.filters[filterIndices.BLACK_AND_WHITE] = false;
      }
      targetObject.applyFilters();
      targetObject.canvas.renderAll();
      setRerenderState(Math.random());
    },
    [targetObject]
  );

  const toggleGrayscale = useCallback(
    (e) => {
      if (e.target.checked) {
        targetObject.filters[filterIndices.GRAYSCALE] = new f.Grayscale();
      } else {
        targetObject.filters[filterIndices.GRAYSCALE] = false;
      }
      targetObject.applyFilters();
      targetObject.canvas.renderAll();
      setRerenderState(Math.random());
    },
    [targetObject]
  );

  return (
    <div className="container mx-auto flex flex-col flex-wrap gap-4 m-8">
      <div className="flex flex-row items-center">
        <input
          type="checkbox"
          className="accent-highlight m-2 mr-4"
          checked={hasBrightnessFilter}
          onChange={toggleBrightness}
        />
        Brightness
        <div className="ml-4">
          <StandardRange
            min={-1}
            max={1}
            step={0.01}
            values={[brightnessVal]}
            onChange={brightnessChanged}
          />
        </div>
      </div>
      <div className="flex flex-row items-center">
        <input
          type="checkbox"
          className="accent-highlight m-2 mr-4"
          checked={hasSaturationFilter}
          onChange={toggleSaturation}
        />
        Saturation
        <div className="ml-4">
          <StandardRange
            min={-1}
            max={1}
            step={0.01}
            values={[saturationVal]}
            onChange={saturationChanged}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row">
          <input
            type="checkbox"
            className="accent-highlight m-2 mr-4"
            checked={hasRemoveColorFilter}
            onChange={toggleRemoveColor}
          />
          Remove color
        </div>
        <div className="flex flex-row m-2 text-xs">
          <div className="flex flex-col">
            Color
            <button
              className="bg-white w-8 h-8 m-0 mt-1 rounded-md flex flex-col place-content-center items-center relative"
              onClick={toggleRemoveColorPicker}
            >
              <div
                className="h-6 w-6 rounded-sm"
                style={{
                  background: removeColor,
                }}
              ></div>
            </button>
            {removeColorPickerState ? (
              <div className="relative m-0 z-10">
                <div className="absolute top-0 left-0">
                  <div
                    className="fixed top-0 left-0 right-0 bottom-0"
                    onClick={toggleRemoveColorPicker}
                  />
                  <ChromePicker
                    color={removeColor}
                    onChange={removeColorChanged}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="flex flex-col ml-4">
            Distance
            <div>
              <div className="mt-4">
                <StandardRange
                  min={0}
                  max={1}
                  step={0.01}
                  values={[removeColorDistance]}
                  onChange={removeDistanceChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center">
        <input
          type="checkbox"
          className="accent-highlight m-2 mr-4"
          checked={hasBlackAndWhiteFilter}
          onChange={toggleBW}
        />
        Black and White
      </div>
      <div className="flex flex-row items-center">
        <input
          type="checkbox"
          className="accent-highlight m-2 mr-4"
          checked={hasGrayscaleFilter}
          onChange={toggleGrayscale}
        />
        Grayscale
      </div>
    </div>
  );
};

export default FilterOptions;
