import React from "react";
import image from "./assets/image.svg";
import text from "./assets/text.svg";
import shapes from "./assets/shapes.svg";

export const Footer = ({
  onAddTextClick,
  onAddImageClick,
  onAddShapeClick,
}) => (
  <footer className="w-full h-18 bg-gray-100 text-white shadow-inner">
    <div className="container mx-auto flex flex-row justify-around">
      <button className="ml-0" onClick={onAddImageClick}>
        <div className="w-12 h-12 m-3 mb-1 bg-highlight rounded-full relative">
          <img alt="add" className="p-3 w-12" src={image} />
        </div>
        <div className="text-black text-sm text-center w-full mb-2">
          Add image
        </div>
      </button>
      <button className="ml-0" onClick={onAddTextClick}>
        <div className="w-12 h-12 m-3 mb-1 bg-highlight rounded-full relative">
          <img alt="text" className="p-3 w-12" src={text} />
        </div>
        <div className="text-black text-sm text-center w-full mb-2">
          Add text
        </div>
      </button>
      <button className="ml-0" onClick={onAddShapeClick}>
        <div className="w-12 h-12 m-3 mb-1 bg-highlight rounded-full relative">
          <img alt="shapes" className="p-3 w-12" src={shapes} />
        </div>
        <div className="text-black text-sm text-center w-full mb-2">
          Add shapes
        </div>
      </button>
    </div>
  </footer>
);

Footer.propTypes = {};

Footer.defaultProps = {};
