import foldArrow from "./assets/fold-arrow.svg";

const OptionsFoldout = ({ isOpen, close, children }) => {
  return (
    <>
      {isOpen && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 mb-18"
          onClick={close}
        />
      )}
      <div
        className={`transition-all absolute bottom-0 left-0 right-0 bg-gray-100 overflow-hidden ${
          isOpen ? "h-96" : "h-0"
        }`}
      >
        <button
          className="h-7 w-full bg-highlight flex flex-row align-center"
          onClick={close}
        >
          <img alt="close" className="mx-auto m-2" src={foldArrow} />
        </button>
        <div className="overflow-y-scroll h-full">{children}</div>
      </div>
    </>
  );
};

export default OptionsFoldout;
