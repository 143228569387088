import { useCallback, useState, useMemo } from "react";
import { ChromePicker } from "react-color";
import rgba from "color-rgba";

import textColorIcon from "./assets/text-color.svg";
import fillColorIcon from "./assets/fill.svg";
import StandardRange from "./StandardRange";

const ShapeOptions = ({ shapeObject }) => {
  const [rerenderState, setRerenderState] = useState(1.1);

  const [fillColorPickerState, setTextColorPickerState] = useState(false);

  const fillColor = useMemo(() => {
    if (shapeObject && rerenderState) {
      const fillColorStr = shapeObject.get("fill");
      const rgbaCol = rgba(fillColorStr);
      return {
        r: rgbaCol[0],
        g: rgbaCol[1],
        b: rgbaCol[2],
        a: rgbaCol[3],
      };
    }
  }, [shapeObject, rerenderState]);

  const strokeColor = useMemo(() => {
    if (shapeObject && rerenderState) {
      const bgColorStr = shapeObject.get("stroke");
      const rgbaCol = rgba(bgColorStr);
      return {
        r: rgbaCol[0],
        g: rgbaCol[1],
        b: rgbaCol[2],
        a: rgbaCol[3],
      };
    }
  }, [shapeObject, rerenderState]);

  const strokeWidth = useMemo(() => {
    if (shapeObject && rerenderState) {
      return parseInt(shapeObject.get("strokeWidth"));
    }
  }, [shapeObject, rerenderState]);

  const toggleFillColorPicker = useCallback(() => {
    setTextColorPickerState(!fillColorPickerState);
  }, [fillColorPickerState]);

  const fillColorChanged = useCallback(
    (color) => {
      setTextColorPickerState({
        ...fillColorPickerState,
        color: color.rgb,
      });
      shapeObject.set({ fill: color.hex });
      shapeObject.canvas.requestRenderAll();
      setRerenderState(Math.random());
    },
    [fillColorPickerState, shapeObject]
  );

  const [strokeColorPickerState, setStrokeColorPickerState] = useState(false);

  const toggleStrokeColorPicker = useCallback(() => {
    setStrokeColorPickerState(!strokeColorPickerState);
  }, [strokeColorPickerState]);

  const strokeColorChanged = useCallback(
    (color) => {
      setStrokeColorPickerState({
        ...strokeColorPickerState,
        color: color.rgb,
      });
      shapeObject.set({ stroke: color.hex });
      shapeObject.canvas.requestRenderAll();
      setRerenderState(Math.random());
    },
    [strokeColorPickerState, shapeObject]
  );

  const setStrokeWidth = useCallback(
    (value) => {
      shapeObject.set({ strokeWidth: value[0] });
      shapeObject.canvas.requestRenderAll();
      setRerenderState(Math.random());
    },
    [shapeObject]
  );

  return (
    <div className="flex flex-row flex-wrap gap-4 m-8">
      <div className="flex flex-col">
        Color
        <div className="flex flex-row mt-2 gap-2">
          <div>
            <button
              className="bg-white w-8 h-8 m-0 rounded-md flex flex-col place-content-center items-center relative"
              onClick={toggleFillColorPicker}
            >
              <img alt="text color" src={fillColorIcon} />
              <div
                className="h-1 w-6 mt-1"
                style={{
                  background: `rgba(${fillColor.r}, ${fillColor.g}, ${fillColor.b}, ${fillColor.a})`,
                }}
              ></div>
            </button>
            {fillColorPickerState ? (
              <div className="relative m-0">
                <div className="absolute top-0 left-0">
                  <div
                    className="fixed top-0 left-0 right-0 bottom-0"
                    onClick={toggleFillColorPicker}
                  />
                  <ChromePicker color={fillColor} onChange={fillColorChanged} />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div>
            <button
              className="bg-white w-8 h-8 m-0 rounded-md flex flex-col place-content-center items-center relative"
              onClick={toggleStrokeColorPicker}
            >
              <img alt="text color" src={textColorIcon} />
              <div
                className="h-1 w-6 mt-1"
                style={{
                  background: `rgba(${strokeColor.r}, ${strokeColor.g}, ${strokeColor.b}, ${strokeColor.a})`,
                }}
              ></div>
            </button>
            {strokeColorPickerState ? (
              <div className="relative m-0">
                <div className="absolute top-0 left-0">
                  <div
                    className="fixed top-0 left-0 right-0 bottom-0"
                    onClick={toggleStrokeColorPicker}
                  />
                  <ChromePicker
                    color={strokeColor}
                    onChange={strokeColorChanged}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        Stroke
        <div className="mt-2 h-8 flex flex-col place-content-center">
          <StandardRange
            min={0}
            max={30}
            values={[strokeWidth]}
            onChange={setStrokeWidth}
          />
        </div>
      </div>
    </div>
  );
};

export default ShapeOptions;
