import { GraphicLab } from './stories/GraphicLab';

function App() {
  return (
    <div className="w-screen h-screen">
      <GraphicLab />
    </div>
  );
}

export default App;
