import React from "react";
import x from "./assets/x.svg";
import refresh from "./assets/refresh.svg";

export const Header = ({onRefresh, onApplyDesign}) => (
  <header className="w-full h-18 text-white">
    <div className="container mx-auto flex flex-row">
      {/* <button className="w-12 h-12 p-3 m-3 ml-0 bg-highlight rounded-full">
        <img src={plus} />
      </button> */}
      <button className="w-12 h-12 m-3 p-3 bg-gray-300 rounded-full" onClick={onRefresh}>
        <img alt="refresh" src={refresh} />
      </button>
      <button className="h-12 m-3 px-4 rounded-full border-highlight border-2 text-highlight uppercase font-bold" onClick={onApplyDesign}>
        Apply design
      </button>
      <div className="flex-1" />
      <button className="m-4">
        <img alt="x" src={x} />
      </button>
    </div>
  </header>
);

Header.propTypes = {};

Header.defaultProps = {};
