import { useCallback, useState, useMemo } from "react";
import { ChromePicker } from "react-color";
import rgba from "color-rgba";

import boldIcon from "./assets/bold.svg";
import italicIcon from "./assets/italic.svg";
import underlineIcon from "./assets/underline.svg";

import textColorIcon from "./assets/text-color.svg";
import fillColorIcon from "./assets/fill.svg";
import alignLeftIcon from "./assets/align-left.svg";
import alignCenterIcon from "./assets/align-center.svg";
import alignRightIcon from "./assets/align-right.svg";
import dropdownIcon from "./assets/dropdown.svg";
import StandardRange from "./StandardRange";

const TextOptions = ({ textObject }) => {
  const [rerenderState, setRerenderState] = useState(1.1);

  const [textColorPickerState, setTextColorPickerState] = useState(false);

  const textColor = useMemo(() => {
    if (textObject && rerenderState) {
      const fillColorStr = textObject.get("fill");
      const rgbaCol = rgba(fillColorStr);
      return {
        r: rgbaCol[0],
        g: rgbaCol[1],
        b: rgbaCol[2],
        a: rgbaCol[3],
      };
    }
  }, [textObject, rerenderState]);

  const bgColor = useMemo(() => {
    if (textObject && rerenderState) {
      const bgColorStr = textObject.get("textBackgroundColor");
      const rgbaCol = rgba(bgColorStr);
      return {
        r: rgbaCol[0],
        g: rgbaCol[1],
        b: rgbaCol[2],
        a: rgbaCol[3],
      };
    }
  }, [textObject, rerenderState]);

  const fontWeight = useMemo(() => {
    if (textObject && rerenderState) {
      return textObject.get("fontWeight");
    }
  }, [textObject, rerenderState]);

  const fontStyle = useMemo(() => {
    if (textObject && rerenderState) {
      return textObject.get("fontStyle");
    }
  }, [textObject, rerenderState]);

  const underline = useMemo(() => {
    if (textObject && rerenderState) {
      return textObject.get("underline");
    }
  }, [textObject, rerenderState]);

  const alignment = useMemo(() => {
    if (textObject && rerenderState) {
      return textObject.get("textAlign");
    }
  }, [textObject, rerenderState]);

  const fontSize = useMemo(() => {
    if (textObject && rerenderState) {
      return parseInt(textObject.get("fontSize"));
    }
  }, [textObject, rerenderState]);

  const toggleTextColorPicker = useCallback(() => {
    setTextColorPickerState(!textColorPickerState);
  }, [textColorPickerState]);

  const textColorChanged = useCallback(
    (color) => {
      setTextColorPickerState({
        ...textColorPickerState,
        color: color.rgb,
      });
      textObject.set({ fill: color.hex });
      textObject.canvas.requestRenderAll();
      setRerenderState(Math.random());
    },
    [textColorPickerState, textObject]
  );

  const [bgColorPickerState, setBgColorPickerState] = useState(false);

  const toggleBgColorPicker = useCallback(() => {
    setBgColorPickerState(!bgColorPickerState);
  }, [bgColorPickerState]);

  const bgColorChanged = useCallback(
    (color) => {
      setBgColorPickerState({
        ...bgColorPickerState,
        color: color.rgb,
      });
      textObject.set({ textBackgroundColor: color.hex });
      textObject.canvas.requestRenderAll();
      setRerenderState(Math.random());
    },
    [bgColorPickerState, textObject]
  );

  const toggleBold = useCallback(() => {
    if (fontWeight === "normal") {
      textObject.set("fontWeight", "bold");
    } else {
      textObject.set("fontWeight", "normal");
    }
    textObject.canvas.requestRenderAll();
    setRerenderState(Math.random());
  }, [textObject, fontWeight]);

  const toggleItalic = useCallback(() => {
    if (fontStyle === "normal") {
      textObject.set("fontStyle", "italic");
    } else {
      textObject.set("fontStyle", "normal");
    }
    textObject.canvas.requestRenderAll();
    setRerenderState(Math.random());
  }, [textObject, fontStyle]);

  const toggleUnderline = useCallback(() => {
    if (underline) {
      textObject.set("underline", false);
    } else {
      textObject.set("underline", true);
    }
    textObject.canvas.requestRenderAll();
    setRerenderState(Math.random());
  }, [textObject, underline]);

  const setAlignment = useCallback(
    (alignment) => {
      textObject.set("textAlign", alignment);
      textObject.canvas.requestRenderAll();
      setRerenderState(Math.random());
    },
    [textObject]
  );

  const setFontSize = useCallback(
    (value) => {
      textObject.set("fontSize", value);
      textObject.canvas.requestRenderAll();
      setRerenderState(Math.random());
    },
    [textObject]
  );

  return (
    <div className="flex flex-row flex-wrap gap-4 m-8">
      <div className="flex flex-col">
        Type options
        <div className="flex flex-row mt-2 gap-2">
          <button
            className={`w-8 h-8 m-0 rounded-md flex place-content-center items-center ${
              fontWeight === "bold" ? "bg-white" : ""
            }`}
            onClick={toggleBold}
          >
            <img alt="bold" src={boldIcon} />
          </button>
          <button
            className={`w-8 h-8 m-0 rounded-md flex place-content-center items-center ${
              fontStyle === "italic" ? "bg-white" : ""
            }`}
            onClick={toggleItalic}
          >
            <img alt="italic" src={italicIcon} />
          </button>
          <button
            className={`w-8 h-8 m-0 rounded-md flex place-content-center items-center ${
              underline ? "bg-white" : ""
            }`}
            onClick={toggleUnderline}
          >
            <img alt="underline" src={underlineIcon} />
          </button>
        </div>
      </div>
      <div className="flex flex-col">
        Alignment
        <div className="flex flex-row mt-2 gap-2">
          <button
            className={`w-8 h-8 m-0 rounded-md flex place-content-center items-center ${
              alignment === "left" ? "bg-white" : ""
            }`}
            onClick={() => setAlignment("left")}
          >
            <img alt="align left" src={alignLeftIcon} />
          </button>
          <button
            className={`w-8 h-8 m-0 rounded-md flex place-content-center items-center ${
              alignment === "center" ? "bg-white" : ""
            }`}
            onClick={() => setAlignment("center")}
          >
            <img alt="align center" src={alignCenterIcon} />
          </button>
          <button
            className={`w-8 h-8 m-0 rounded-md flex place-content-center items-center ${
              alignment === "right" ? "bg-white" : ""
            }`}
            onClick={() => setAlignment("right")}
          >
            <img alt="align right" src={alignRightIcon} />
          </button>
        </div>
      </div>
      <div className="flex flex-col">
        Color
        <div className="flex flex-row mt-2 gap-2">
          <div>
            <button
              className="bg-white w-8 h-8 m-0 rounded-md flex flex-col place-content-center items-center relative"
              onClick={toggleTextColorPicker}
            >
              <img alt="text color" src={textColorIcon} />
              <div
                className="h-1 w-6 mt-1"
                style={{
                  background: `rgba(${textColor.r}, ${textColor.g}, ${textColor.b}, ${textColor.a})`,
                }}
              ></div>
            </button>
            {textColorPickerState ? (
              <div className="relative m-0">
                <div className="absolute top-0 left-0">
                  <div
                    className="fixed top-0 left-0 right-0 bottom-0"
                    onClick={toggleTextColorPicker}
                  />
                  <ChromePicker color={textColor} onChange={textColorChanged} />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div>
            <button
              className="bg-white w-8 h-8 m-0 rounded-md flex flex-col place-content-center items-center relative"
              onClick={toggleBgColorPicker}
            >
              <img alt="text color" src={fillColorIcon} />
              <div
                className="h-1 w-6 mt-1"
                style={{
                  background: `rgba(${bgColor.r}, ${bgColor.g}, ${bgColor.b}, ${bgColor.a})`,
                }}
              ></div>
            </button>
            {bgColorPickerState ? (
              <div className="relative m-0">
                <div className="absolute top-0 left-0">
                  <div
                    className="fixed top-0 left-0 right-0 bottom-0"
                    onClick={toggleBgColorPicker}
                  />
                  <ChromePicker color={bgColor} onChange={bgColorChanged} />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        Font
        <select
          className="w-48 mt-2 h-8 px-4 pr-8 appearance-none rounded bg-no-repeat"
          style={{
            backgroundImage: `url(${dropdownIcon})`,
            backgroundPosition: "right .75rem center",
          }}
        >
          <option>Poppins</option>
        </select>
      </div>
      <div className="flex flex-col">
        Font size
        <div className="mt-2 h-8 flex flex-col place-content-center">
          <StandardRange
            min={1}
            max={200}
            values={[fontSize]}
            onChange={setFontSize}
          />
        </div>
      </div>
    </div>
  );
};

export default TextOptions;
