import { Range } from "react-range";

const StandardRange = (props) => {
  return (
    <Range
      renderTrack={({ props, children }) => (
        <div className="h-1 w-48 bg-highlight rounded-full" {...props}>
          {children}
        </div>
      )}
      renderThumb={({ props }) => (
        <div {...props} className="w-4 h-4 rounded-full bg-highlight" />
      )}
      {...props}
    />
  );
};

export default StandardRange;
